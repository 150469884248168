<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <div>
        <b-card-title>Items Mistery Box</b-card-title>
        </div>
        <div>
           <feather-icon
          icon="DownloadIcon"
          size="21"
           class="cursor-pointer"
           @click="downloadfileExample"
            v-b-tooltip.hover.top="'Download CSV Example'"
        />
        </div>
      </b-card-header>
      <b-card-body>
        <b-form @submit.prevent="insertItem">
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group label="Item Title">
                <b-form-input
                  type="text"
                  v-model="item.title"
                  placeholder="Item Title"
                  required
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
                <b-form-group label="Load a CSV">
                     <b-form-file
                     v-model="item.filecsv"
                     placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                      required
                      @input="readCSV"
                      /> 
                </b-form-group>
            </b-col>
            <b-col>
              <b-button type="submit" variant="primary" class="mr-1">
                Save
              </b-button>
              <b-button type="reset" variant="outline-secondary">
                Reset
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card-body>
    </b-card>

    <b-card no-body>
      <b-card-header>
        <b-card-title>Mistery Box Items</b-card-title>
      </b-card-header>
      <b-card-body>
         <div class="row border p-1" style="border-radius: 10px;margin-left:30px;margin-right:30px">
      <div class="col-3"><h6 class="text-left">#</h6></div>
      <div class="col-3"><h6 class="text-left">Title</h6></div>
      <div class="col-3"><h6 class="text-center">Image</h6></div>
      <div class="col-3"><h6 class="text-right">Stock</h6></div>
    </div>
        <ul class="container mt-1">
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="scroll-area"
        v-if="items.length > 0"
      >
        <b-list-group flush v-for="(item, i) in items" :key="i">
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
          <b-col cols="3">
            <span>{{ i+1}}</span>
            </b-col>
            <b-col cols="3">
            <h6>{{ item.title }}</h6>
            </b-col>
            <b-col cols="3" class="texto">
              <a :href="item.url" target="_blank" >{{ item.url }}</a>
            </b-col>
            <b-col cols="3">
              <div class="text-right">
            <b-badge variant="light-success" pill  class="badge-round">
              {{ item.stock }}
            </b-badge>
            </div>
            </b-col>
          </b-list-group-item>
        </b-list-group>
      </vue-perfect-scrollbar>
    </ul>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BForm,
  BFormGroup,
  BRow,
  BCol,
  BFormInput,
  BFormTextarea,
  BButton,
  BFormFile,
  VBTooltip,
   BListGroup, BListGroupItem, BBadge
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive"
import UserService from "@/services/UserService"
import VuePerfectScrollbar from "vue-perfect-scrollbar";
export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    BFormInput,
    BFormTextarea,
    BButton,
    BFormFile,
    VBTooltip,
    VuePerfectScrollbar, BListGroup, BListGroupItem, BBadge
  },
  directives:{
    Ripple,
      'b-tooltip': VBTooltip,
  },
  data() {
    return {
      item: {
        title: null,
        stock: 0,
        filecsv:null,
      },
        perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
      items:[]
    }
  },
  mounted(){
    this.gwtItemsMisteryBox()
  },
  methods: {
    downloadfileExample:function(){
       const link = document.createElement("a");
          link.href = "/api/template/mysterytemplate.csv";
          link.setAttribute("download", 'mysterytemplate.csv');
        document.body.appendChild(link);
        link.click();
    },
      readCSV: function(input) {
      const vm = this;
      if (input) {
        var reader = new FileReader();
        reader.onload = function(e) {
          vm.item.filecsv = reader.result;
        var arr=vm.csvToArray(vm.item.filecsv)
        vm.item.filecsv=arr
        console.log(vm.item.filecsv);
        };
       reader.readAsText(input);
      }
    },
    insertItem:async function () {
      const ii=(await UserService.insertMisteryBox(JSON.parse(localStorage.getItem("auth")).discordId,this.item)).data
           this.$swal({
          title: "",
          text: "MisteryBox item saved successfull",
          icon: "success",
           allowOutsideClick: false,
          showClass: {
            popup: "animate__animated animate__fadeIn",
          },
          customClass: {
            confirmButton: "btn btn-success",
          },
          buttonsStyling: false,
        }).then(result => {
        if (result.value) {
                this.$router.go(0)
         }
      });
    },
    gwtItemsMisteryBox:async function(){
      const gi=(await UserService.getItemsMisteryBox(JSON.parse(localStorage.getItem("auth")).discordId)).data
      console.log(gi);
      this.items=gi

    },
    csvToArray:function (csv) {
  var lines = csv.split("\n");
  var result = [];
  var headers = lines[0].split(",");

  for(var i = 1; i < lines.length; i++) {
    var obj = {};
    var currentline = lines[i].split(",");

    for(var j = 0; j < headers.length; j++) {
      obj[headers[j]] = currentline[j];
    }

    result.push(obj);
  }

  return result;
}
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
.ps {
  height: 200px;
}
.texto {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

</style>